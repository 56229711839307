<template>
  <div class="hello">
    <h1 v-if="chess" :class="chess.turn() === 'w' ? 'white' : 'black'">
      {{ chess.turn() === "w" ? "Whites" : "Blacks" }} turn
    </h1>
    <h1 v-if="chess">
      {{ chess.game_over() ? "game over" : "playing" }}
    </h1>
    <canvas id="granim"></canvas>
    <a-scene cursor="rayOrigin:mouse">
      <a-assets> </a-assets>
      <a-entity
        camera
        look-controls="magicWindowTrackingEnabled: false"
        orbit-controls="target: 0 1.6 0; minDistance: 0.5; maxDistance: 180; initialPosition: 0 5 5"
      ></a-entity>
      <a-entity light="type: ambient; color: #BBB"></a-entity>
      <a-entity
        light="type: directional; color: #FFF; intensity: 0.15"
        position="-0.5 1 1"
      ></a-entity>

      <a-entity class="board" position="-4 -2 -4">
        <a-entity class="row" v-for="(row, x) in board" :key="x">
          <a-entity class="cell" v-for="(cell, y) in row" :key="y">
            <!-- :material="`color: ${
                AAMoves.includes(coordinate(x, y))
                  ? 'green'
                  : cellColor(x, y)
              }; src: textures/marble-${cellColor(x, y)}.jpg`" -->
            <a-entity
              :class="coordinate(x, y)"
              :geometry="`primitive: plane;`"
              :material="` src: ${
                AAMoves.includes(coordinate(x, y))
                  ? '#granim'
                  : 'textures/marble-' + cellColor(x, y) + '.jpg'
              }; transparent: true; opacity: ${
                AAMoves.includes(coordinate(x, y)) ? 0.7 : 1
              }`"
              :text="`align: center; color: red; width: 6; value: ${
                debug ? coordinate(x, y) : ''
              }`"
              canvas-updater
              :position="`${y} 0 ${x}`"
              rotation="-90 0 0"
              @click="squareClick(x, y, cell, $event)"
            ></a-entity>

            <a-gltf-model
              v-if="cell"
              :src="`/pieces/${cell.type}.glb`"
              scale="0.2 0.2 0.2"
              :position="`${y} 0 ${x}`"
              :rotation="`0 ${cell.color === 'w' ? 0 : 180} 0`"
              @model-loaded="pieceArrange($event, cell.color)"
              :color="cell.color === 'w' ? 'white' : 'black'"
              @click="pieceClick(x, y, cell, $event)"
            >
              <a-text
                :position="`0 1 0`"
                rotation="0 0 0"
                color="green"
                :value="cell.type.toUpperCase()"
              >
              </a-text>
            </a-gltf-model>
          </a-entity>
        </a-entity>
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
import Chess from "chess.js";
import Granim from "granim";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data: function () {
    return {
      debug: false,
      chess: null,
      board: null,
      letters: ["a", "b", "c", "d", "e", "f", "g", "h"],
      nums: ["8", "7", "6", "5", "4", "3", "2", "1"],
      availableMoves: [],
      AAMoves: [],
      move: [],
    };
  },
  mounted() {
    this.chess = new Chess();
    this.board = this.chess.board();

    var granimInstance = new Granim({
      element: "#granim",
      name: "granim",
      states: {
        "default-state": {
          direction: "top-bottom",
          transitionSpeed: 2500,
          gradients: [
            ["#ff9966", "#ff5e62"],
            ["#00F260", "#0575E6"],
            ["#e1eec3", "#f05053"],
          ],
        },
      },
    });

    AFRAME.registerComponent("canvas-updater", {
      schema: {
        enabled: { type: "boolean", default: false },
      },
      dependencies: ["geometry", "material"],
      tick: function () {
        if (this.data.enabled) {
          var el = this.el;
          var material;
          material = el.getObject3D("mesh").material;
          if (!material.map) return;
          material.map.needsUpdate = true;
        }
      },
    });
    // this.chess = chess;
    // console.log(typeof this.chess);
    // console.log(typeof this.chess.board());
    // console.log(typeof JSON.parse(JSON.stringify(this.chess.board())));
    // console.table(this.board);
  },

  methods: {
    pieceArrange: function (e, clr) {
      let color = clr === "w" ? "white" : "black";
      // console.log(e.target.object3D.rotation.y);
      // if (clr === "b") e.target.object3D.rotation.y = 3.1;
      e.target.object3D.children[1].children[0].material = new THREE.MeshPhongMaterial(
        { color: color }
      );
      e.target.object3D.children[1].children[0].material.needsUpdate = true;
      e.target.object3D.children[1].children[0].material.color.needsUpdate = true;
    },
    openSpots: function (x, y, coord) {
      for (let i = 0; i < this.availableMoves.length; i++) {
        const move = this.availableMoves[i];
        this.AAMoves.push(move.to);
      }
    },
    pieceClick: function (x, y, cell, e) {
      let coord = this.coordinate(x, y);
      this.AAMoves = [];
      this.availableMoves = this.chess.moves({
        square: coord,
        verbose: true,
      });
      this.openSpots(x, y, coord);
      this.move[0] = coord;
      this.updateBoard();

      // }
    },
    squareClick: function (x, y, cell, e) {
      let coord = this.coordinate(x, y);

      this.move[1] = coord;
      this.chess.move({ from: this.move[0], to: this.move[1], sloppy: true });
      this.clearArrays();
      this.updateBoard();
      console.log(this.chess.ascii());
    },
    updateBoard: function (x, y) {
      this.board = this.chess.board();
    },
    clearArrays: function (x, y) {
      this.AAMoves = [];
      this.move = [];
    },
    coordinate: function (x, y) {
      return this.squareLetter(x, y) + this.squareNum(x, y);
    },
    squareLetter: function (x, y) {
      return this.letters[y];
    },
    squareNum: function (x, y) {
      return this.nums[x];
    },
    cellColor: function (i, j) {
      let row = i;
      let cell = j;
      let color;
      // every odd row
      if (row % 2 !== 0) {
        cell % 2 !== 0 ? (color = "white") : (color = "black");
      } else {
        cell % 2 !== 0 ? (color = "black") : (color = "white");
      }
      return color;
    },
  },
  computed: {
    // board: function () {
    //   console.log(this.chess.board());
    //   return this.chess.board();
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn {
  position: absolute;
  z-index: 1;
}
.white {
  color: white;
}
.black {
  color: black;
}
#granim {
  position: absolute;
  width: 1px;
  height: 1px;
}
</style>
